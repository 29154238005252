import dayjs from 'dayjs'
import {Dancer} from 'models'
import {Group} from 'models/dancer'
import {$, k} from '../i18n/localization'

const DATE_PRIX_2025 = '2025-02-08' // 8 feb 2025

export const getAge = (dancer: Dancer, short?: boolean) => {
  const ageDate = dayjs(DATE_PRIX_2025)
  const birthDate = dayjs(dancer.Birthdate)

  const years = ageDate.diff(birthDate, 'year')
  const months = ageDate.diff(birthDate.add(years, 'year'), 'month')

  if (short) {
    return `${years}.${months}`
  }

  return `${years} years, ${months} months`
}

export const getSeniority = (dancer: Dancer) => {
  if (dancer.Group) {
    if ([Group.GirlsA, Group.BoysA].includes(dancer.Group)) return $(k.junior)
    if ([Group.GirlsB, Group.BoysB].includes(dancer.Group)) return $(k.senior)
  }
  return '-'
}
