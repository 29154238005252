import {Box, Center, CenterProps, Image, ScaleFade, Text, VStack} from '@chakra-ui/react'
// import Lottie from 'react-lottie'
// import animationData from './animation_lln2xzc7.json'
// import image_logo from './logo_loading.png'
import sponsorImage from '../../assets/sponsor.png'
import image_logo from './logo_loading.png'

const LoadingAnimation = (props: CenterProps) => {
  return (
    <Center w='full' {...props}>
      <VStack p={0} maxW='500px' w='500px' alignContent='center' pos='relative' spacing={8}>
        {/* use only image for now with animation fade-in out (to 20%) */}
        <ScaleFade in={true} unmountOnExit={true} initialScale={0.9}>
          <Image
            src={image_logo}
            alt='Forum Prix de Lausanne'
            width={500}
            height={500}
            style={{
              objectFit: 'contain',
            }}
          />
        </ScaleFade>

        <Box bg='white' p={4} maxW='300px'>
          <VStack spacing={2}>
            <Text fontSize='sm' color='gray.600' fontStyle='italic' textAlign='center'>
              With the generous support of
            </Text>{' '}
            <Image
              src={sponsorImage}
              alt='Sponsor'
              objectFit='contain'
              width='100%'
              height='auto'
            />
          </VStack>
        </Box>
      </VStack>
    </Center>
  )
}

export default LoadingAnimation
