import { Avatar, Box, Heading, HStack, IconButton, Text } from '@chakra-ui/react'
import { School } from 'models'
import { FaArrowRight } from 'react-icons/fa6'
import { RiBankLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { ROUTE_SCHOOL } from '../../router/routes'

interface Props {
  school: School
}

const MatchingSchoolItem = ({school}: Props) => (
  <HStack spacing={3} bgColor='brand.matchingBg' w='full' py={4}>
    <Avatar src={school.picture} icon={<RiBankLine />} loading='lazy' />
    <Box flex={1}>
      <Heading as='h4'>{school.name}</Heading>
      <HStack fontSize='2xs' spacing={1}>
        <Text>{school.country}</Text>
        <Text>•</Text>
        <Text>{school.minAge}</Text>
        <Text>•</Text>
        <Text>
          {school.languages?.map((language: string) => language?.substring(0, 2))?.join('/')}
        </Text>
      </HStack>
    </Box>

    <Link to={ROUTE_SCHOOL.replace(':id', school.id.toString())}>
      <IconButton aria-label='go to school' variant='linkGold' size='sm' icon={<FaArrowRight />} />
    </Link>
  </HStack>
)

export default MatchingSchoolItem
