import {Avatar, Button, Checkbox, Heading, HStack, Text, useTheme, VStack} from '@chakra-ui/react'
import {Dancer} from 'models'
import {FaCheckCircle} from 'react-icons/fa'
import {RiAddFill, RiBankLine, RiDraggable, RiLockLine, RiUserLine} from 'react-icons/ri'
import {Case, Switch} from 'react-if'
import usePhaseData from '../../hooks/usePhaseData'
import {$, k} from '../../i18n/localization'
import {smoothClasses} from '../../styles/theme/CustomCheckbox'
import {getAge, getSeniority} from '../../utils/dancerHelper'
import {getImageUrl} from '../../utils/imageProxy'
import {ItemWrapper} from '../Common/ItemWrapper'

interface Props {
  dancer: Dancer
  selected?: boolean
  draggable?: boolean
  checkbox?: boolean
  interested?: boolean
  onAddInterested?: (dancer: Dancer) => void
  hideArrow?: boolean
}

const DancerItem = (props: Props) => {
  const {dancer, selected, draggable, checkbox, interested, onAddInterested} = props
  const theme = useTheme()
  const {disableAdminEditFeatures, disableDancerEditFeatures} = usePhaseData()

  return (
    <ItemWrapper {...props}>
      <HStack spacing={3}>
        <Switch>
          <Case condition={draggable && !disableAdminEditFeatures}>
            <RiDraggable />
          </Case>
          <Case condition={!draggable}>
            <RiUserLine />
          </Case>
        </Switch>
        <Avatar
          src={
            dancer.Profile_Picture
              ? getImageUrl({
                  url: dancer.Profile_Picture,
                  width: 200,
                  height: 200,
                  position: 'top',
                })
              : undefined
          }
          icon={<RiUserLine />}
          loading='lazy'
          size='lg'
        />
        <VStack alignItems='start'>
          <HStack>
            <Heading as='h3' whiteSpace='nowrap'>
              {dancer.Firstname} {dancer.Lastname}
            </Heading>
            <Heading>•</Heading>
            <Heading as='h3' fontWeight='unset' color='brand.colors.text01'>
              {dancer.REF}
            </Heading>
          </HStack>
          <HStack fontSize='sm' overflowX='hidden'>
            <Text whiteSpace='nowrap'>{getAge(dancer, true)}</Text>
            <Text>•</Text>
            <Text whiteSpace='nowrap'>{dancer.Nationalities}</Text>
            <Text>•</Text>
            <Text whiteSpace='nowrap'>{getSeniority(dancer)}</Text>
            <Text>•</Text>
            <Text whiteSpace='nowrap'>{dancer.Gender}</Text>
          </HStack>
          <HStack>
            {!draggable && (
              <Button size='sm' leftIcon={<RiBankLine />} fontWeight='normal'>
                {dancer.schools?.length || 0}
              </Button>
            )}
            {disableDancerEditFeatures ? (
              <Button variant='secondary' size='sm' leftIcon={<RiLockLine />} fontWeight='normal'>
                {$(k.locked)}
              </Button>
            ) : dancer.confirmed ? (
              <HStack spacing={1}>
                <FaCheckCircle color={theme.colors.brand.success} />
                <Text color={theme.colors.brand.success}>{$(k.confirmed)}</Text>
              </HStack>
            ) : null}
            {interested && (
              <Text color='brand.success' whiteSpace='nowrap'>
                ♡ {$(k.interested)}
              </Text>
            )}
          </HStack>
        </VStack>
      </HStack>
      <HStack>
        {onAddInterested && !disableAdminEditFeatures && (
          <Button px='unset' onClick={() => onAddInterested?.(dancer)}>
            <RiAddFill />
          </Button>
        )}
        {/* {!props.hideArrow && (
          <Link to={schoolLink}>
            <IconButton
              aria-label='go to school'
              variant='linkGold'
              size='sm'
              icon={<FaArrowRight />}
            />
          </Link>
        )} */}
        {checkbox && <Checkbox isChecked={selected} sx={smoothClasses} pointerEvents='none' />}
      </HStack>
    </ItemWrapper>
  )
}

export default DancerItem
