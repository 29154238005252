export const en = {
  back: `Back`,
  genericError: `Oops! Something went wrong. Please try later.`,
  error: `Error`,
  logout: `Logout`,
  welcomeMessage: `Welcome to the Prix de Lausanne Networking Forum.`,
  notSupported: `is not supported. Please switch to another device`,
  dancerChoice: `Dancers choice`,
  selections: `selections`,
  selection: `selection`,
  lockSelection: `Lock selection`,
  partnersChoice: `Partners choice`,
  phase: `Phase`,
  publishResults: `Publish results`,
  publish: `Publish`,
  publishAllResults: `Send the results to all!`,
  results: `Results`,
  editSchools: `Edit school`,
  cancel: `Cancel`,
  edit: `Edit`,
  calculatedResults: `Matches`,
  number: `Number`,
  age: `Age`,
  nationality: `Nationality`,
  category: `Category`,
  senior: `Senior`,
  confirmedSeniority: `Confirmed`,
  junior: `Junior`,
  interestedDancers: `Interested dancers`,
  additional: `additional`,
  interestedDancersDesc: `interested dancers`,
  interested: `interested`,
  confirmed: `confirmed`,
  locked: `locked`,
  clickDragDesc: `Click & drag to change the order`,
  sponsoredCandidate: 'Sponsored candidate',
  noDancersPlaceholder: `No chosen dancers yet`,
  name: `Name`,
  gender: `Gender`,
  remove: `Remove`,
  add: `Add`,
  details: `Details`,
  mySelection: `My selection`,
  searchSchools: `Search for a school`,
  availableSchools: `Available schools`,
  searchResult: `Search result`,
  country: `Country`,
  language: `Language`,
  minimumAge: `Minimum age`,
  searchDancers: `Search for a dancer`,
  availableDancers: `Available dancers`,
  tutorialDancer: `This is the Prix de Lausanne Networking Forum. Here you can create a list of your favourite schools that you would like to go to. With this list, we will then create the opportunity for you to meet up with the direction of the chosen schools in order to convince them to take you in.`,
  tutorialSchool: `This is the Prix de Lausanne Networking Forum. Here you can create a list of your favourite dancers you would like to meet. With this list, we will then create the opportunity for you to meet up with the dancers in order to convince them to consider your school.`,
  welcomeSchool: `Welcome School!`,
  welcome: `Welcome`,
  editDancers: `Edit dancers`,
  schoolsUpdated: `Schools updated`,
  noSchoolsPlaceholder: `No chosen schools yet`,
  searchSchoolsOrDancers: `Search for a school or dancer`,
  dancers: `dancers`,
  schools: `schools`,
  dancerSelectionPhase: `sureToPublishResults`,
  schoolSelectionPhase: `School selection phase`,
  publishResultsPhase: `Publish results phase`,
  lockSelectionFor: `Lock selection for`,
  sureToPublish: `Are you sure you would like to lock the ability to manipulate and change the selections for the {0}?`,
  sureToPublishResults: `All schools & dancers will receive the results of the networking forum matches. Are you sure you want to publish the results?`,
  sureToPublishResultsDesc: `When you are ready, you may click on publish results. This will send every dancer & school the list of matches they have in the golden area.`,
  yourMatches: `Matches`,
  otherInterestedSchools: `Other interested schools`,
  clearMySelection: `Clear my selection`,
  areYouSureClearDancers: `Are you sure you want to clear your selection? All current dancers in the list will be removed.`,
  confirmMySelection: `Confirm my selection`,
  confirmMySelectionDesc: `By confirming your selection, the Prix de Lausanne will do its best to setup meetings with your favourite dancers in order to discuss potential partnerships.`,
  confirm: `Confirm`,
  selectionIsNowClosed: `Selection is now closed`,
  chosenDancers: `Chosen dancers`,
  clearSelection: `Clear selection`,
  pressAndDragDesc: `Press & drag to change the order of importance`,
  checkOutTheResults: `Check out the results!`,
  unlockSelection: `Unlock selection`,
  otherInterestedDancers: `Other interested dancers`,
  areYouSureClearSchools: `Are you sure you want to clear your selection? All current schools in the list will be removed.`,
  confirmMySelectionSchoolsDesc: `By confirming your selection, the Prix de Lausanne will do its best to setup meetings with your favourite schools in order to discuss potential partnerships.`,
  chosenSchools: `Chosen schools`,
  resetPassword: `Reset password`,
  resetLinkSent: `A reset link has been sent. Please check your email and follow the instructions.`,
  backToLogIn: `Back to login`,
  pleaseEnterEmail: `Please enter your email address and we will send you a link to reset your password.`,
  email: `Email`,
  sendMeAResetLink: `Send me a reset link`,
  pleaseEnterPassword: `Please enter your new password`,
  newPassword: `New password`,
  password: `Password`,
  newPasswordConfirmation: `New password confirmation`,
  changePassword: `Change password`,
  login: `Login`,
  forgottenYourPassword: `Forgotten your password?`,
}
