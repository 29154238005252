const extractOriginalUrl = (url: string): string => {
  try {
    // Check if it's already a weserv.nl URL
    if (url.includes('images.weserv.nl')) {
      const urlParams = new URL(url).searchParams
      const originalUrl = urlParams.get('url')
      if (originalUrl) return originalUrl
    }
    return url
  } catch (e) {
    return url
  }
}

export const getImageUrl = ({
  url,
  width,
  height,
  fit = 'cover',
  position = 'center',
  smartCrop,
  trim,
  fpx,
  fpy,
}: {
  url?: string
  width: number
  height?: number
  fit?: 'cover' | 'contain'
  position?:
    | 'center'
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'top-left'
    | 'bottom-left'
    | 'bottom-right'
    | 'top-right'
  smartCrop?: 'entropy' | 'attention'
  trim?: number
  fpx?: number // focal point x (0 to 1)
  fpy?: number // focal point y (0 to 1)
}) => {
  //if no url, return a placholder light grey image
  if (!url) {
    return ''
    // return `https://placehold.co/${width ?? 100}x${height ?? 100}?text=~`
  }

  // Extract original URL if it's already a weserv.nl URL
  const originalUrl = extractOriginalUrl(url)

  //only add width and height if they are not 0 or undefined
  const params: string[] = []
  if (width !== 0 && width !== undefined) {
    params.push(`w=${width}`)
  }
  if (height !== 0 && height !== undefined) {
    params.push(`h=${height}`)
  }
  if (fit !== undefined) {
    params.push(`fit=${fit}`)
  }

  // Handle alignment and cropping
  if (fit === 'cover') {
    if (smartCrop) {
      params.push(`a=${smartCrop}`)
    } else if (fpx !== undefined && fpy !== undefined) {
      params.push(`a=focal`)
      params.push(`fpx=${fpx}`)
      params.push(`fpy=${fpy}`)
    } else {
      params.push(`a=${position}`)
    }
  }

  // Add trim if specified
  if (trim !== undefined) {
    params.push(`trim=${trim}`)
  }

  return `https://images.weserv.nl/?url=${originalUrl}&${params.join('&')}`
}
