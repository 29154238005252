import Strapi from 'strapi-sdk-js'
import {isDev} from '../const'

const apiUrl = import.meta.env.VITE_API_BASE_URL

const BASE_URL =
  apiUrl || (isDev ? 'http://127.0.0.1:1337' : 'https://dashboard.prixdelausanne.org')

export const strapi = new Strapi({url: BASE_URL})

export const logout = () => {
  strapi.logout()
  strapi.removeToken()
}

strapi.axios.interceptors.response.use(
  response => response,
  error => {
    console.log('axios error', error)
    if (error?.status === 401 || error.response?.status === 401) {
      logout()
    }
    return Promise.reject(error)
  },
)
