import {Avatar, Box, Checkbox, Heading, HStack, IconButton, Text, useTheme} from '@chakra-ui/react'
import {School} from 'models'
import {FaArrowRight} from 'react-icons/fa6'
import {RiAddLine, RiBankLine, RiDraggable, RiSubtractLine} from 'react-icons/ri'
import {Link} from 'react-router-dom'
import usePhaseData from '../../hooks/usePhaseData'
import {ROUTE_SCHOOL} from '../../router/routes'
import {smoothClasses} from '../../styles/theme/CustomCheckbox'
import {getImageUrl} from '../../utils/imageProxy'
import {ItemWrapper} from '../Common/ItemWrapper'

interface Props {
  school: School
  selected?: boolean
  draggable?: boolean
  onToggle?: (school: School) => void
  checkbox?: boolean
  hideArrow?: boolean
}

const MobileSchoolItem = (props: Props) => {
  const {school, selected, draggable, onToggle, checkbox} = props
  const {disableDancerEditFeatures} = usePhaseData()
  const theme = useTheme()

  const schoolLink = ROUTE_SCHOOL.replace(':id', school.id.toString())
  return (
    <ItemWrapper
      {...props}
      // desactivate for now because other buttons are not clickable then
      // linkTo={schoolLink}
    >
      <HStack spacing={3} w='full'>
        {draggable && !disableDancerEditFeatures && <RiDraggable />}
        <Box pos='relative'>
          <Avatar
            size='md'
            src={
              school.picture
                ? getImageUrl({
                    url: school.picture,
                    width: 100,
                    height: 100,
                    fit: 'contain',
                    position: 'center',
                  })
                : undefined
            }
            icon={<RiBankLine />}
            bgColor='gray.100'
            sx={{
              '& img': {
                objectFit: 'contain',
                width: '100%',
                height: '100%',
              },
            }}
          />
          {/* {school.confirmed && (
            <Box bgColor='white' pos='absolute' bottom={0} borderRadius='full'>
              <FaCircleCheck color={theme.colors.brand.success} />
            </Box>
          )} */}
        </Box>
        <Box flex={1}>
          <Heading as='h4'>{school.name}</Heading>
          <HStack fontSize='2xs' spacing={1}>
            <Text>{school.country}</Text>
            <Text>•</Text>
            <Text>{school.minAge}</Text>
            <Text>•</Text>
            <Text>
              {school.languages?.map((language: string) => language?.substring(0, 2))?.join('/')}
            </Text>
          </HStack>
        </Box>
        <HStack>
          {onToggle && !disableDancerEditFeatures && (
            <IconButton
              size='sm'
              aria-label='toggle add'
              icon={selected ? <RiSubtractLine /> : <RiAddLine />}
              fontWeight='normal'
              onClick={() => onToggle(school)}
            />
          )}
          {!props.hideArrow && (
            <Link to={schoolLink}>
              <IconButton
                aria-label='go to school'
                variant='linkGold'
                size='sm'
                icon={<FaArrowRight />}
              />
            </Link>
          )}
        </HStack>
      </HStack>
      {checkbox && <Checkbox isChecked={selected} sx={smoothClasses} pointerEvents='none' />}
    </ItemWrapper>
  )
}

export default MobileSchoolItem
