import {
  AspectRatio,
  Avatar,
  Box,
  Divider,
  Heading,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import {RiBankLine} from 'react-icons/ri'
import {useParams} from 'react-router-dom'
import {$, k} from '../../i18n/localization'
import {NAVBAR_HEIGHT_WITH_PADDING} from '../../styles/theme'
import Store from '../../utils/Store'
import {BoxDetail} from '../Common/BoxDetail'
import DancerCalculatedResults from '../Dancer/DancerCalculatedResults'
import DancerInterested from '../Dancer/DancerInterested'
import DancerSelection from '../Dancer/DancerSelection'

const SchoolDetails = () => {
  const {id} = useParams()
  const schoolObs = Store.schools.find(s => s.id.toString() === id)!
  const school = schoolObs?.peek()
  if (!school) return null

  return (
    <VStack alignItems='start' spacing={5}>
      <HStack w='full'>
        <AspectRatio w='290px' ratio={1}>
          <Box position='relative' w='100%' h='100%'>
            <Avatar
              src={school.picture}
              bgColor='gray.400'
              borderRadius='2xl'
              icon={<RiBankLine size='120' />}
              sx={{
                '& img': {
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                },
              }}
              w='100%'
              h='100%'
            />
          </Box>
        </AspectRatio>
        <VStack flex={1} alignSelf='stretch'>
          <BoxDetail h='full'>
            <Text>{$(k.name)}</Text>
            <Heading>{school.name}</Heading>
            {school.description && (
              <Text mt={2} color='gray.600' fontSize='sm'>
                {school.description}
              </Text>
            )}
          </BoxDetail>
          <HStack w='full'>
            <BoxDetail>
              <Text>{$(k.country)}</Text>
              <Heading as='h4'>{school.country}</Heading>
            </BoxDetail>
            <BoxDetail>
              <Text>{$(k.minimumAge)}</Text>
              <Heading as='h4'>{school.minAge}</Heading>
            </BoxDetail>
            <BoxDetail>
              <Text>Languages</Text>
              <Heading as='h4'>{school.languages?.join(', ')}</Heading>
            </BoxDetail>
          </HStack>
        </VStack>
      </HStack>
      <VStack
        spacing={5}
        w='full'
        maxH={`calc(100vh - ${NAVBAR_HEIGHT_WITH_PADDING}px - 315px)`}
        overflowY='scroll'>
        <Divider />
        <DancerCalculatedResults school={schoolObs} />
        <DancerSelection school={schoolObs} />
        <Divider />
        <DancerInterested school={schoolObs} />
        <Spacer />
      </VStack>
    </VStack>
  )
}

export default SchoolDetails
