import {Avatar, Box, Heading, HStack, IconButton, Text} from '@chakra-ui/react'
import {Dancer} from 'models'
import {FaArrowRight} from 'react-icons/fa6'
import {RiUserLine} from 'react-icons/ri'
import {Link} from 'react-router-dom'
import {Role} from '../../models/Role'
import {ROUTE_DANCER} from '../../router/routes'
import {getAge, getSeniority} from '../../utils/dancerHelper'
import Store from '../../utils/Store'

interface Props {
  dancer: Dancer
}

const MatchingDancerItem = ({dancer}: Props) => {
  const role = Store.role.use()
  const isAdmin = role === Role.SUPER_ADMIN

  return (
    <HStack spacing={3} bgColor='brand.matchingBg' w='full' py={4}>
      <Avatar src={dancer.Profile_Picture} icon={<RiUserLine />} loading='lazy' />
      <Box flex={1}>
        <HStack>
          <Heading as={isAdmin ? 'h3' : 'h4'}>
            {dancer.Firstname} {dancer.Lastname}
          </Heading>
          <Heading>•</Heading>
          <Heading as={isAdmin ? 'h3' : 'h4'} fontWeight='unset' color='brand.colors.text01'>
            {dancer.REF}
          </Heading>
        </HStack>
        <HStack fontSize={isAdmin ? 'sm' : '2xs'} spacing={1}>
          <Text>{getAge(dancer, true)}</Text>
          <Text>•</Text>
          <Text>{dancer.Nationalities}</Text>
          <Text>•</Text>
          <Text>{getSeniority(dancer)}</Text>
          <Text>•</Text>
          <Text>{dancer.Gender}</Text>
        </HStack>
      </Box>
      {!isAdmin && (
        <Link to={ROUTE_DANCER.replace(':id', dancer.id.toString())}>
          <IconButton
            aria-label='go to school'
            variant='linkGold'
            size='sm'
            icon={<FaArrowRight />}
          />
        </Link>
      )}
    </HStack>
  )
}

export default MatchingDancerItem
