import {
  AspectRatio,
  Avatar,
  Box,
  Center,
  Heading,
  HStack,
  Text,
  useTheme,
  useToast,
  VStack,
} from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import { Dancer, School } from 'models'
import { SchoolProps } from 'models/interfaces'
// import dynamic from 'next/dynamic'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { RiUserLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import strapi from '../../api'
import usePhaseData from '../../hooks/usePhaseData'
import { $, k } from '../../i18n/localization'
import { FLOATING_HEADER_HEIGHT } from '../../styles/theme'
import { swapArray } from '../../utils/dataHelper'
import { getItemStyle, getListStyle } from '../Common/dragAndDropStyles'
import { handleError } from '../Common/handleError'
import AddEditDancers from '../School/AddEditDancers'
import DancerItem from './DancerItem'

// const Droppable = dynamic(async () => (await import('react-beautiful-dnd')).Droppable, {
//   ssr: false,
// })
// import {Droppable} from 'react-beautiful-dnd'

const DancerSelection = ({school}: SchoolProps) => {
  const {id} = useParams()
  const dancers = school.selectedDancers.use()
  const theme = useTheme()
  const toast = useToast()
  const {disableAdminEditFeatures} = usePhaseData()

  const saveDancers = async (newDancers: Dancer[], onDrag: boolean = false) => {
    try {
      await strapi.update<School>('schools', id as string, {
        selectedDancers: newDancers.map(s => s.id),
      })
      if (onDrag) return
      school.selectedDancers.set(newDancers)
      toast({
        title: 'Dancers updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (e) {
      handleError(e)
    }
  }

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return
    const newDancers = swapArray(dancers, result.source.index, result.destination.index)
    school.selectedDancers.set(newDancers)
    await saveDancers(newDancers, true)
  }

  return (
    <VStack alignItems='start' w='full' spacing={4}>
      <HStack pos='sticky' top={0} bgColor='white' w='full' zIndex={1} h={FLOATING_HEADER_HEIGHT}>
        <Heading>Chosen dancers</Heading>
        {!disableAdminEditFeatures && <Text>{$(k.clickDragDesc)}</Text>}
      </HStack>
      <Box px={2}>
        <Text fontStyle='italic' color={theme.colors.brand.text03} fontSize='xs'>
          Rank the dancers by preferences
        </Text>
      </Box>
      <Center px={2} w='full'>
        <Box w='full' boxShadow='rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'>
          {isEmpty(dancers) ? (
            <Center p={10}>
              <VStack>
                <AspectRatio ratio={1} w='100px'>
                  <Avatar borderRadius='full' icon={<RiUserLine size='60' />} />
                </AspectRatio>
                <Text>{$(k.noDancersPlaceholder)}</Text>
              </VStack>
            </Center>
          ) : (
            <VStack spacing='1px' bgColor='brand.surface02' w='full'>
              {/* @ts-ignore */}
              <DragDropContext onDragEnd={onDragEnd}>
                {/* @ts-ignore */}
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver, theme.colors.brand.surface02)}>
                      {dancers.map((item, index) => (
                        /* @ts-ignore */
                        <Draggable
                          key={item.id.toString()}
                          draggableId={item.id.toString()}
                          index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}>
                              <DancerItem
                                dancer={item}
                                draggable
                                interested={Boolean(school.dancers.get().find(s => s.id === item.id))}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </VStack>
          )}
        </Box>
      </Center>
      <AddEditDancers selected={dancers} saveDancers={saveDancers} />
    </VStack>
  )
}

export default DancerSelection
