import {
  Avatar,
  Box,
  Checkbox,
  Heading,
  HStack,
  IconButton,
  Text,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import {Dancer} from 'models'
import {FaArrowRight} from 'react-icons/fa6'
import {RiAddLine, RiDraggable, RiSubtractLine, RiUserLine} from 'react-icons/ri'
import {Link} from 'react-router-dom'
import usePhaseData from '../../hooks/usePhaseData'
import {$, k} from '../../i18n/localization'
import {ROUTE_DANCER} from '../../router/routes'
import {smoothClasses} from '../../styles/theme/CustomCheckbox'
import {getAge, getSeniority} from '../../utils/dancerHelper'
import {ItemWrapper} from '../Common/ItemWrapper'

interface Props {
  dancer: Dancer
  selected?: boolean
  draggable?: boolean
  interested?: boolean
  onToggle?: (dancer: Dancer) => void
  checkbox?: boolean
  hideArrow?: boolean
}

const MobileDancerItem = (props: Props) => {
  const {dancer, selected, draggable, onToggle, checkbox, interested} = props
  const {disableSchoolEditFeatures} = usePhaseData()
  const theme = useTheme()

  const dancerLink = ROUTE_DANCER.replace(':id', dancer.id.toString())
  return (
    <ItemWrapper
      {...props}
      // desactivate for now because other buttons are not clickable then
      // linkTo={dancerLink}
    >
      <HStack spacing={3} w={checkbox ? 'calc(100% - 50px)' : 'full'}>
        {draggable && !disableSchoolEditFeatures && <RiDraggable />}
        <Box pos='relative'>
          <Avatar size='md' src={dancer.Profile_Picture} icon={<RiUserLine />} />
          {/* {dancer.confirmed && (
            <Box bgColor='white' pos='absolute' bottom={0} borderRadius='full'>
              <FaCircleCheck color={theme.colors.brand.success} />
            </Box>
          )} */}
        </Box>
        <VStack flex={1} alignItems='start' spacing={0} overflowX='hidden'>
          <HStack>
            <Heading as='h4' whiteSpace='nowrap'>
              {dancer.Firstname} {dancer.Lastname}
            </Heading>
            <Heading>•</Heading>
            <Heading as='h3' fontWeight='unset' color='brand.colors.text01'>
              {dancer.REF}
            </Heading>
          </HStack>
          <HStack fontSize='2xs' spacing={1}>
            <Text whiteSpace='nowrap'>{getAge(dancer, true)}</Text>
            <Text>•</Text>
            <Text whiteSpace='nowrap'>{dancer.Nationalities}</Text>
            <Text>•</Text>
            <Text whiteSpace='nowrap'>{getSeniority(dancer)}</Text>
            <Text>•</Text>
            <Text whiteSpace='nowrap'>{dancer.Gender}</Text>
          </HStack>
          {interested && (
            <Text color='brand.success' fontSize='2xs'>
              ♡ {$(k.interested)}
            </Text>
          )}
        </VStack>
        <HStack>
          {onToggle && !disableSchoolEditFeatures && (
            <IconButton
              size='sm'
              aria-label='toggle add'
              icon={selected ? <RiSubtractLine /> : <RiAddLine />}
              fontWeight='normal'
              onClick={() => onToggle(dancer)}
            />
          )}
          {!props.hideArrow && (
            <Link to={dancerLink}>
              <IconButton
                aria-label='go to dancer'
                variant='linkGold'
                size='sm'
                icon={<FaArrowRight />}
              />
            </Link>
          )}
        </HStack>
      </HStack>
      {checkbox && <Checkbox isChecked={selected} sx={smoothClasses} pointerEvents='none' />}
    </ItemWrapper>
  )
}

export default MobileDancerItem
